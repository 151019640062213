

import React, { useEffect, useState } from "react";
import CustomRadioChip from "../../../../helpers/radio-chip";
import {
    Box,
    Button,
    FormHelperText,
    FormLabel,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
    CONTACT_STATE,
    TASK_LENDERS_CONDITION,
    TASK_PRIORITY,
    TASK_TYPE_CHOICE,
    UPDATE,
} from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import RadioButtonGroup from "../../../../helpers/radio-button-group";
import {
    getApiCallDynamic,
    postApiCallDynamicWithOutReturn,
    uploadFile,
} from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import {
    DateToTimestapmFormater,
    TimeStampToMonthDateYearForInput,
} from "../../../../helpers/time-formater";
import { currentDate } from "../../../../helpers/common_fuctions/current-date";
import { KSCloudUploadIcon } from "../../../../icons/custome-icon";
import { toastNotify } from "../../../../helpers/alert-msg";
import { TaskValidation } from "../../../../constants/validation-rules";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import MultiSelectDropDown from "../../../../helpers/dropdown/multi-select-dropdown";
import SupportingDocument from "./supporting-doc";
import ThirdPartTaskForm from "./third-party-task-form";
import { DocumentDialogCommonHooks, DocumentDialogCommonHooksTask } from "../../../../helpers/modal/modal";
import UploadFromLocal from "./upload-from-local";
import DocumentSelection from "./documents-selection";



export default function SupportingDocumentForm({
    formId,
    taskFormData,
    handleClose,
    setLocalData,
    docList,
    completedLoanDocs
}) {



    const filteredDataLibrary = docList.filter(item => item.taskMaster.taskTypeFor === "other" && item.blankDocument === true);
    // const filteredDataLoanDocuments = docList.filter(item => item.taskMaster.taskTypeFor === "other");

    const updatedDataLibrary = filteredDataLibrary.map((doc) => {
        const { documentType, ...rest } = doc;
        return { documentName: documentType, ...rest };
    });



    const localUploadDocType = docList
        // .filter(item => item.taskMaster.taskTypeFor === "other")
        .map(item => ({
            label: item.documentType,
            value: item.documentType
        }));





    const [brokerFileID, setBrokerFileID] = useState(null);
    const [disableTaskCreate, setDisableTaskCreate] = useState(false)



    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {

        },
    });



    const { taskType } = useWatch({ control })
    const [file, setFile] = useState(null);



    const [taksModal, setTaksModal] = useState(false);

    const handleTaskModalOpen = () => {
        setTaksModal(true);
    };

    const handleTaskModalClose = () => {
        setTaksModal(false);
    };

    const [editItem, setEditItem] = useState(null)



    const handleFormSubmit = (formData) => {

        setLocalData(formData)

    };
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [isDocumentSelectionOpen, setIsDocumentSelectionOpen] = useState(false);
    const [currentDocumentList, setCurrentDocumentList] = useState([]);




    const handleDocumentSelect = (docs) => {

        if (utilityName === 'CliQloan Library - Supporting Documents') {
            setSelectedDocuments(docs);

            setLocalData(docs)
            setIsDocumentSelectionOpen(false);
        }
        if (utilityName === 'Loan Documents') {


            const updatedDocs = docs.map(doc => {
                if (doc.storageDocId !== undefined) {
                    return {
                        ...doc,
                        docId: doc.storageDocId,
                        storageDocId: undefined
                    };
                }
                return doc;
            });
            setSelectedDocuments(updatedDocs)
            // setSelectedDocuments(docs);

            setLocalData(updatedDocs)
            setIsDocumentSelectionOpen(false);


        }
    };

    const [utilityName, setUtilityName] = useState(null)

    const handleCliQloanLibraryOpen = () => {
        setUtilityName('CliQloan Library - Supporting Documents')
        setCurrentDocumentList(updatedDataLibrary);
        setIsDocumentSelectionOpen(true);
    };

    const handleLoanDocumentsOpen = () => {
        setUtilityName('Loan Documents')
        setCurrentDocumentList(completedLoanDocs);
        setIsDocumentSelectionOpen(true);
    };
    return (
        <>
            <Box
                sx={{
                    minHeight: '70vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >

                <Stack spacing={2}>
                    <Button
                        type="button"
                        onClick={handleTaskModalOpen}
                        variant="contained"

                    >
                        Upload from Local
                    </Button>
                    <Button type="button" onClick={handleCliQloanLibraryOpen} variant="contained">Upload from CliQloan Library</Button>
                    <Button type="button" onClick={handleLoanDocumentsOpen} variant="contained">Upload from Loan Documents</Button>
                </Stack>
            </Box >
            {isDocumentSelectionOpen && (
                <DocumentSelection

                    documents={currentDocumentList}
                    utilityName={utilityName}
                    onClose={() => setIsDocumentSelectionOpen(false)}
                    onSelect={handleDocumentSelect}
                />
            )}
            <DocumentDialogCommonHooksTask
                title={`Add document`}
                show={taksModal}
                handleClose={handleTaskModalClose}
                body={
                    <UploadFromLocal
                        formId={formId}
                        handleClose={handleTaskModalClose}
                        onSubmit={handleFormSubmit}
                        editItem={editItem}
                        documentTypes={localUploadDocType}
                        setLocalData={setLocalData}

                    />
                }
            />


        </>
    );
}
