import { Box, Button, Grid, IconButton, Typography, } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { CREATE, UPDATE } from "../../../../constants/constatnt-variable";
import ConfirmationModel from "../../../../helpers/modal/confirm-modal";
import { DialogCommonHooks } from "../../../../helpers/modal/modal";
import CustomReactMaterialTable from "../../../../helpers/table/react-material-table";
import { KSPencilAltIcon, KSTrashIcon } from "../../../../icons/custome-icon";
import { webInfoState } from "../../../../recoil/profile-atom";
import { API } from "../../../../services/api-config";
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import RatesForm from "./rates-form";
import { AttachMoney } from "@mui/icons-material";

function Rates(props) {
  const [openModal, setModalOpen] = useState(false);

  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setAction(CREATE);
    setData("");
  };

  const [action, setAction] = useState(() => {
    return CREATE;
  });
  const [data, setData] = useState(() => {
    return "";
  });

  let webInfo = useRecoilValue(webInfoState);

  const handleEdit = (cell, row) => {
    setData(row.original);
    setAction(UPDATE);
    setModalOpen(true);
  };
  const handleDelete = (cell, row) => {
    let apiData = {
      websiteId: webInfo.id,
      id: row.original.id,
    };

    postApiCallDynamicWithOutReturn(
      {
        data: apiData,
        refresh: () => {
          setTableReload((prev) => !prev);
        },
        path: API.rates,
        header: "delete"
      }
    );
  };

  const formatNumberWithThousandSeparator = (value) => {
    if (!value) return "";
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "loanYear",
        header: "Loan Term",

        // Cell: ({ cell, row }) => (
        //   <MuLink
        //     component={"button"}
        //     onClick={() =>
        //   >
        //     {cell.getValue()}
        //   </MuLink>
        // ),
        enableColumnActions: false,
        enableSorting: false,
      },
      // {
      //   accessorKey: "loanType",
      //   header: "Loan Type",

      //   // Cell: ({ cell, row }) => (
      //   //   <MuLink
      //   //     component={"button"}
      //   //     onClick={() =>
      //   //   >
      //   //     {cell.getValue()}
      //   //   </MuLink>
      //   // ),
      //   enableColumnActions: false,
      //   enableSorting: false,
      // },
      //column definitions...
      {
        accessorKey: "loanAmountUpto",
        header: "Loan Amount",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => formatNumberWithThousandSeparator(cell.getValue()),
      },
      {
        accessorKey: "mortgageRate",
        header: "Mortgage Rate",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "loanCategory",
        header: "Loan Category",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "id",
        header: "Action",
        enableColumnActions: false,
        enableSorting: false,

        Cell: ({ cell, column, row, table }) => {
          return (
            <Box display={"flex"}>
              <IconButton>
                <KSPencilAltIcon
                  onClick={() => {
                    handleEdit(cell, row);
                  }}
                />
              </IconButton>

              <ConfirmationModel
                body={
                  <IconButton>
                    <KSTrashIcon />
                  </IconButton>
                }
                handleSubmit={() => {
                  handleDelete(cell, row);
                }}
                message={`Are you sure you want to delete the rate?`}
                title={"Confirmation"}
              />
            </Box>
          );
        },
      },
      //end
    ],
    []
  );

  const [tableReload, setTableReload] = useState(() => false);
  if (webInfo.id == undefined) {
    return <></>;
  }

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={5} my={1}>
          <Typography variant="h3" mb={1}>Rates</Typography>
          <Typography variant="p2" color={"rgba(34, 34, 34, 75%)"}>
            Add Mortgage Rates
          </Typography>
        </Grid>
        <Grid item sm={12} md={7} my={1} overflow={'auto'}>
          <Box my={1}>
            <CustomReactMaterialTable
              enableToolbarInternalActions={false}
              // initialSorting={[{ id: "firstName", desc: false }]}
              endPoint={API.rates}
              params={{ websiteId: webInfo.id }}
              columns={columns}
              refetch={tableReload}
              // staticLoad={true}
              // staticData={data}
              enableSorting={false}
              enableGlobalFilter={false}
              enablePagination={false}
              enableColumnFilters={false}
              renderTopToolbarCustomActions={(table) => (
                <Box my={1} display="flex" direction="row" justifyContent="end">
                  <Button variant="contained" onClick={handleClickOpen}>
                    Add New
                  </Button>
                </Box>
              )}
            />
          </Box>

          <DialogCommonHooks
            title="New Mortgage Rates"
            show={openModal}
            handleClose={handleClose}
            body={
              <RatesForm
                handleClose={handleClose}
                action={action}
                setTableReload={setTableReload}
                data={data}
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Rates;
