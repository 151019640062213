// import React from 'react';
// import {
//     Box,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Typography,
//     Paper,
//     Grid,
//     Divider
// } from "@mui/material";
// import Logo from "../../../icons/cliqloanblack.png"

// const Invoice = ({ invoiceDetails }) => {




//     const formattedDateTime = new Intl.DateTimeFormat('en-US', {
//         year: 'numeric',
//         month: 'short', // Use short month format (e.g., "Dec")
//         day: 'numeric',
//         hour: '2-digit',
//         minute: '2-digit',
//         second: '2-digit',
//         timeZone: 'UTC',
//         timeZoneName: 'short',
//     }).format(new Date(invoiceDetails.invoicePaymentTime));


//     return (
//         <Box
//             width={'625px'}
//             borderTop={'7px solid #2F80ED'}
//         // sx={{
//         //     border: '1px solid #DBDBDB',
//         //     backgroundColor: '#FAFAFA',
//         //     marginBottom: '25px',
//         //     padding: 2,
//         // }}
//         >
//             {/* First box - Left side */}


//             {/* Invoice No */}

//             {/* Separator line */}
//             {/* <Divider
//                 sx={{
//                     marginY: 2,
//                 }}
//             /> */}

//             {/* Second box */}
//             {/* Left side */}
//             <TableContainer style={{ backgroundColor: '#F8FBFC' }} component={Paper}>
//                 <Table>
//                     <TableBody>
//                         <TableRow>
//                             <TableCell style={{ verticalAlign: 'top' }}>
//                                 {/* Replace 'Logo' with your actual logo */}
//                                 <a href="/" style={{ textDecoration: 'none' }}>
//                                     <img src={Logo} className="imglogo" alt="Logo" height={19} width={111} />
//                                 </a>
//                             </TableCell>
//                             <TableCell style={{ textAlign: 'right' }}>
//                                 <span style={{ fontWeight: '700' }}>Invoice No:</span> {invoiceDetails.invoiceNumber} <br />
//                                 <span style={{ fontWeight: '700' }}>Invoice Date:</span> {invoiceDetails.invoiceDate}<br />
//                                 <span style={{ fontWeight: '700' }}>Billing Period:</span> {`${invoiceDetails.billPeriodFrom} to ${invoiceDetails.billPeriodTo}`}
//                             </TableCell>
//                         </TableRow>


//                         <TableRow>
//                             <TableCell sx={{ fontWeight: 'bold' }}>
//                                 To <br />
//                                 {invoiceDetails.toName} <br />
//                                 {invoiceDetails.toAddress}

//                             </TableCell>

//                             <TableCell style={{ textAlign: 'right' }}>
//                                 From <br />

//                                 {invoiceDetails.fromName} <br />
//                                 {invoiceDetails.fromAddress} <br />
//                             </TableCell>


//                         </TableRow>

//                         <TableRow>
//                             <TableCell sx={{ fontWeight: 'bold' }} style={{ paddingLeft: '50px', backgroundColor: 'white' }}>Bill name</TableCell>
//                             <TableCell style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '50px' }}>{invoiceDetails.toName}</TableCell>
//                         </TableRow>
//                         <TableRow>
//                             <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Type</TableCell>
//                             <TableCell style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '50px' }}>One Time</TableCell>
//                         </TableRow>
//                         <TableRow style={{ backgroundColor: 'white' }}>
//                             <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px', margin: 0 }}>Active users</TableCell>
//                             <TableCell style={{ backgroundColor: 'white' }}></TableCell>
//                         </TableRow>
//                         {invoiceDetails.invoiceItem.some((item) => item.itemType === 'Billing Determinant') && (
//                             <>
//                                 <TableRow ml={3} style={{ backgroundColor: 'white', paddingLeft: '59px', margin: 0, paddingTop: '10px' }}>
//                                     <TableCell>
//                                         {invoiceDetails.invoiceItem &&
//                                             invoiceDetails.invoiceItem
//                                                 .filter((item) => item.itemType === 'Billing Determinant')
//                                                 .map((item) => (
//                                                     <div key={item.index} style={{ height: '35px', width: '100%', borderBottom: 0, padding: '5px', backgroundColor: 'white', fontWeight: 'bold' }} >
//                                                         <li style={{ paddingLeft: '50px' }}> {item.itemLabel}</li> <br />
//                                                     </div>
//                                                 ))}
//                                     </TableCell>
//                                     <TableCell>
//                                         {invoiceDetails.invoiceItem &&
//                                             invoiceDetails.invoiceItem
//                                                 .filter((item) => item.itemType === 'Billing Determinant')
//                                                 .map((item) => (
//                                                     <div key={item.index} style={{ height: '35px', display: 'flex', flexDirection: 'row', textAlign: 'right', justifyContent: 'end', paddingRight: '30px' }}>

//                                                         <div style={{ height: '35px', borderBottom: 0, padding: '5px', backgroundColor: 'white' }}>
//                                                             {item.count} * {item.rate}<br />

//                                                         </div>
//                                                         <div style={{ height: '35px', borderBottom: 0, padding: '5px', backgroundColor: 'white' }}>
//                                                             =<br />

//                                                         </div>
//                                                         <div style={{ height: '35px', borderBottom: 0, padding: '5px', backgroundColor: 'white' }}>
//                                                             {item.count * item.rate}<br />

//                                                         </div>
//                                                     </div>
//                                                 ))}
//                                     </TableCell>
//                                 </TableRow>

//                             </>
//                         )}
//                         <TableRow>
//                             <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Gross Amount</TableCell>
//                             <TableCell style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '50px' }}>{invoiceDetails.grossTotal}</TableCell>
//                         </TableRow>
//                         {invoiceDetails.invoiceItem.some((item) => item.itemType === 'Coupan') && (
//                             <>
//                                 <TableRow>
//                                     <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Coupons</TableCell>
//                                 </TableRow>

//                                 <TableRow>
//                                     <Box ml={3}>
//                                         {invoiceDetails.invoiceItem &&
//                                             invoiceDetails.invoiceItem
//                                                 .filter((item) => item.itemType === 'Coupan')
//                                                 .map((item) => (
//                                                     <TableRow key={item.invoiceItemId}>

//                                                         <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white' }}>
//                                                             {item.itemLabel} <br />

//                                                         </TableCell>

//                                                         <TableCell style={{ backgroundColor: 'white' }}>
//                                                             {item.count} * {item.rate}<br />

//                                                         </TableCell>
//                                                         <TableCell style={{ backgroundColor: 'white' }}>
//                                                             =<br />

//                                                         </TableCell>
//                                                         <TableCell style={{ backgroundColor: 'white' }}>
//                                                             {item.count * item.rate}<br />

//                                                         </TableCell>

//                                                     </TableRow>
//                                                 ))}

//                                     </Box>
//                                 </TableRow>
//                             </>
//                         )}
//                         {invoiceDetails.invoiceItem.some((item) => item.itemType === 'Discount') && (
//                             <>
//                                 <TableRow>
//                                     <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Discounts</TableCell>
//                                     <TableCell style={{ backgroundColor: 'white' }}></TableCell>
//                                 </TableRow>

//                                 <TableRow ml={3} style={{ backgroundColor: 'white', paddingLeft: '59px', margin: 0 }}>
//                                     <TableCell>
//                                         {invoiceDetails.invoiceItem &&
//                                             invoiceDetails.invoiceItem
//                                                 .filter((item) => item.itemType === 'Discount')
//                                                 .map((item) => (
//                                                     <div key={item.invoiceItemId}>
//                                                         <div style={{ backgroundColor: 'white', paddingLeft: '50px' }} > {item.itemLabel}</div>

//                                                     </div>

//                                                 ))}
//                                     </TableCell>
//                                     <TableCell>
//                                         {invoiceDetails.invoiceItem &&
//                                             invoiceDetails.invoiceItem
//                                                 .filter((item) => item.itemType === 'Discount')
//                                                 .map((item) => (
//                                                     <div key={item.invoiceItemId}>
//                                                         <div style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '30px' }} >  {item.amount}</div>
//                                                     </div>
//                                                 ))}
//                                     </TableCell>
//                                 </TableRow>

//                             </>
//                         )}


//                         {invoiceDetails.invoiceItem.some((item) => item.itemType === 'Coupon') && (
//                             <>
//                                 <TableRow>
//                                     <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Coupons</TableCell>
//                                     <TableCell style={{ backgroundColor: 'white' }}></TableCell>
//                                 </TableRow>

//                                 <TableRow ml={3} style={{ backgroundColor: 'white', paddingLeft: '59px', margin: 0 }}>
//                                     <TableCell>
//                                         {invoiceDetails.invoiceItem &&
//                                             invoiceDetails.invoiceItem
//                                                 .filter((item) => item.itemType === 'Coupon')
//                                                 .map((item) => (
//                                                     <div key={item.invoiceItemId}>
//                                                         <div style={{ backgroundColor: 'white', paddingLeft: '50px' }} > {item.itemLabel.replace("Coupon", "Coupon ").replace("applied", " applied")}</div>

//                                                     </div>

//                                                 ))}
//                                     </TableCell>
//                                     <TableCell>
//                                         {invoiceDetails.invoiceItem &&
//                                             invoiceDetails.invoiceItem
//                                                 .filter((item) => item.itemType === 'Coupon')
//                                                 .map((item) => (
//                                                     <div key={item.invoiceItemId}>
//                                                         <div style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '30px' }} >  {item.amount}</div>
//                                                     </div>
//                                                 ))}
//                                     </TableCell>
//                                 </TableRow>

//                             </>
//                         )}






//                         <TableRow>
//                             <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Tax</TableCell>
//                             <TableCell style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '50px' }}>{invoiceDetails.tax}</TableCell>
//                         </TableRow>

//                         {/* <Box sx={{ border: '1px solid #DBDBDB', backgroundColor: '#FAFAFA', marginBottom: '20px' }} >
//                         </Box> */}

//                         <TableRow>
//                             <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Total payable amount</TableCell>
//                             <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '50px' }}>${invoiceDetails.netTotal}</TableCell>
//                         </TableRow>



//                     </TableBody>
//                 </Table>
//             </TableContainer>
//             {/* <Box>
//                 <TableContainer component={Paper}>
//                     <Table>
//                         <TableBody>

//                             <TableRow>
//                                 <TableCell sx={{ fontWeight: 'bold' }}>Total payable amount</TableCell>
//                                 <TableCell>${invoiceDetails.netTotal}</TableCell>
//                             </TableRow>

//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             </Box> */}
//         </Box>
//     );
// };

// export default Invoice;


import React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Grid,
    Divider
} from "@mui/material";
import Logo from "../../../icons/cliqloanblack.png"
import { format } from 'date-fns';
const Invoice = ({ invoiceDetails }) => {




    const formattedDateTime = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short', // Use short month format (e.g., "Dec")
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC',
        timeZoneName: 'short',
    }).format(new Date(invoiceDetails.invoicePaymentTime));


    return (
        <Box
            width={'625px'}
            borderTop={'7px solid #2F80ED'}
        // sx={{
        //     border: '1px solid #DBDBDB',
        //     backgroundColor: '#FAFAFA',
        //     marginBottom: '25px',
        //     padding: 2,
        // }}
        >
            {/* First box - Left side */}


            {/* Invoice No */}

            {/* Separator line */}
            {/* <Divider
                sx={{
                    marginY: 2,
                }}
            /> */}

            {/* Second box */}


            {/* Left side */}
            {/* <TableContainer style={{ backgroundColor: '#F8FBFC' }} component={Paper}>
                <Table>
                    <TableBody>

                    </TableBody>
                </Table>
            </TableContainer> */}
            <TableContainer style={{
                //  backgroundColor: '#F8FBFC'
            }} component={Paper}>
                <Grid container padding={1}>

                    {/* <Grid item xs={12} md={6}>
                        <a href="/" style={{ textDecoration: 'none' }}>
                            <img
                                src={Logo}
                                className="imglogo"
                                alt="Logo"
                            // height={'100%'}
                            // width={'100%'}
                            />
                        </a>
                    </Grid>

                  

                     <Grid item xs={12} md={6}>
                        <div style={{ textAlign: 'right' }}>
                            <span style={{}}>Invoice No:</span> {invoiceDetails.invoiceNumber} <br />
                            <span style={{}}>Invoice Date:</span> {invoiceDetails.invoiceDate}<br />
                            <span style={{}}>Billing Period:</span> {`${invoiceDetails.billPeriodFrom} to ${invoiceDetails.billPeriodTo}`}
                        </div>
                    </Grid> 


                    <Grid item xs={12} md={2}>
                        <Typography
                            sx={{ backgroundColor: 'white' }}
                            style={{ textAlign: 'left' }}
                        >
                            Invoice No:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography
                            sx={{ backgroundColor: 'white' }}
                            style={{ textAlign: 'right' }}
                        >
                            {invoiceDetails.invoiceNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography
                            sx={{ backgroundColor: 'white' }}
                            style={{ textAlign: 'left' }}
                        >
                            Invoice Date:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography
                            sx={{ backgroundColor: 'white' }}
                            style={{ textAlign: 'right' }}
                        >
                            {invoiceDetails.invoiceDate}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography
                            sx={{ backgroundColor: 'white' }}
                            style={{ textAlign: 'left' }}
                        >
                            Billing Period:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography
                            sx={{ backgroundColor: 'white' }}
                            style={{ textAlign: 'right' }}
                        >
                            {`${invoiceDetails.billPeriodFrom} to ${invoiceDetails.billPeriodTo}`}
                        </Typography>
                    </Grid> */}
                    <Grid container spacing={2}>
                        {/* Logo Section */}
                        <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'center' }}>
                            <a href="/" style={{ textDecoration: 'none' }}>
                                <img
                                    src={Logo}
                                    className="imglogo"
                                    alt="Logo"
                                    style={{ height: 'auto', width: 'auto' }} // Optional: Adjust the image size as needed
                                />
                            </a>
                        </Grid>

                        {/* Invoice Details Section */}
                        <Grid item xs={12} md={7}>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography style={{ textAlign: 'left', backgroundColor: 'white' }}>
                                        Invoice No:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography style={{ textAlign: 'right', backgroundColor: 'white' }}>
                                        {invoiceDetails.invoiceNumber}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Typography style={{ textAlign: 'left', backgroundColor: 'white' }}>
                                        Invoice Date:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography style={{ textAlign: 'right', backgroundColor: 'white' }}>
                                        {invoiceDetails.invoiceDate}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Typography style={{ textAlign: 'left', backgroundColor: 'white' }}>
                                        Billing Period:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography style={{ textAlign: 'right', backgroundColor: 'white' }}>
                                        {`${invoiceDetails.billPeriodFrom} to ${invoiceDetails.billPeriodTo}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} md={12} style={{ paddingTop: '20px' }}>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography sx={{}}>
                            To: <br />
                            Name: {invoiceDetails.toName} <br />
                            Address: {invoiceDetails.toAddress}
                        </Typography>
                    </Grid>

                    {/* "From" Section */}
                    <Grid item xs={12} md={6}>
                        <Typography style={{ textAlign: 'right' }}>
                            From: <br />
                            Name: {invoiceDetails.fromName} <br />
                            Address: {invoiceDetails.fromAddress}
                        </Typography>
                    </Grid>


                    <Grid item xs={12} md={12} style={{ paddingTop: '20px' }}>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography
                            sx={{ backgroundColor: 'white' }}
                            style={{ textAlign: 'left' }}
                        >
                            Bill Name
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{ backgroundColor: 'white', textAlign: 'right' }}
                        >
                            {invoiceDetails.toName}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography
                            sx={{ backgroundColor: 'white' }}
                            style={{ textAlign: 'left' }}
                        >
                            Type
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{ backgroundColor: 'white', textAlign: 'right' }}
                        >
                            One Time
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ paddingTop: '20px' }}>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Typography
                            style={{ backgroundColor: 'lightGrey', textAlign: 'left' }}
                        >
                            Item Description
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography
                            style={{ backgroundColor: 'lightGrey', textAlign: 'right' }}
                        >
                            Quantity
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography
                            style={{ backgroundColor: 'lightGrey', textAlign: 'right' }}
                        >
                            Unit Price
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography
                            style={{ backgroundColor: 'lightGrey', textAlign: 'right' }}
                        >
                            Total
                        </Typography>
                    </Grid>

                    {invoiceDetails.invoiceItem
                        .filter((item) => item.itemType === 'Billing Determinant')
                        .map((item, index) => (
                            <Grid
                                container
                                key={item.invoiceItemId}
                                style={{
                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                                    padding: '10px 0',
                                }}
                            >
                                <Grid item xs={12} md={3}>
                                    <Typography style={{ textAlign: 'left' }}>
                                        {item.itemLabel || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography style={{ textAlign: 'right' }}>
                                        {item.count || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography style={{ textAlign: 'right' }}>
                                        ${item.rate.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography style={{ textAlign: 'right' }}>
                                        ${item.amount.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    <Grid item xs={12} md={12} style={{ paddingTop: '20px' }}>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography
                            sx={{ backgroundColor: 'lightGrey' }}
                            style={{ textAlign: 'left' }}
                        >
                            Gross Total:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{ backgroundColor: 'lightGrey', textAlign: 'right' }}
                        >
                            ${invoiceDetails.grossTotal.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ paddingTop: '20px' }}>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Typography
                            sx={{ backgroundColor: 'lightGrey' }}
                            style={{ textAlign: 'left' }}
                        >
                            Coupon & Discounts:
                        </Typography>
                    </Grid>





                    {invoiceDetails.invoiceItem
                        .filter((item) => item.itemType === 'Coupon')
                        .map((item, index) => (
                            <Grid
                                container
                                key={item.invoiceItemId}
                                style={{
                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                                    padding: '10px 0',
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Typography style={{ textAlign: 'left' }}>
                                        {item.itemLabel.replace("Coupon", "Coupon ").replace("applied", " applied") || 'N/A'}
                                        {/* {item.itemLabel || 'N/A'} */}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography style={{ textAlign: 'right' }}>
                                        ${item.amount.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}

                    {invoiceDetails.invoiceItem
                        .filter((item) => item.itemType === 'Discount')
                        .map((item, index) => (
                            <Grid
                                container
                                key={item.invoiceItemId}
                                style={{
                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                                    padding: '10px 0',
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Typography style={{ textAlign: 'left' }}>
                                        {item.itemLabel || 'N/A'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography style={{ textAlign: 'right' }}>
                                        ${item.amount.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    <Grid item xs={12} md={12} style={{ paddingTop: '20px' }}>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            sx={{ backgroundColor: 'lightGrey' }}
                            style={{ textAlign: 'left' }}
                        >
                            Total payable amount:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{ backgroundColor: 'lightGrey', textAlign: 'right' }}
                        >
                            ${invoiceDetails.netTotal}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={12} style={{ paddingTop: '20px' }}>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            sx={{ backgroundColor: 'lightGrey' }}
                            style={{ textAlign: 'left' }}
                        >
                            Payment Status
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{ backgroundColor: 'lightGrey', textAlign: 'right' }}
                        >
                            <span style={{}}>
                                {invoiceDetails.invoicePaymentStatus}
                            </span>{' '}
                            (Dated : {format(new Date(invoiceDetails.invoicePaymentTime), 'yyyy-MM-dd')})
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography
                            sx={{ backgroundColor: 'white' }}
                            style={{ textAlign: 'left' }}
                        >
                            Ref No:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{ backgroundColor: 'white', textAlign: 'right' }}
                        >
                            {invoiceDetails.transactionId}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            sx={{ backgroundColor: 'white' }}
                            style={{ textAlign: 'left' }}
                        >
                            Amount Paid:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            style={{ backgroundColor: 'white', textAlign: 'right' }}
                        >
                            ${invoiceDetails.netTotal}
                        </Typography>
                    </Grid>

                </Grid>




            </TableContainer>

        </Box >
    );
};

export default Invoice;
