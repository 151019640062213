// // import { Box, Button } from "@mui/material";
// // import { useFormik } from "formik";
// // import React, { useState } from "react";
// // import { useRecoilValue } from "recoil";
// // import { MODAL_ACTION, TYPES_OF_LOAN, UPDATE } from "../../../../constants/constatnt-variable";
// // import CustomInput from "../../../../helpers/custom-input";
// // import useInput from "../../../../helpers/use-input";
// // import { webInfoState } from "../../../../recoil/profile-atom";
// // import { API } from "../../../../services/api-config";
// // import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
// // import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";

// // export default function RatesForm({
// //   data,
// //   handleClose,
// //   setTableReload,
// //   ...props
// // }) {
// //   let webInfo = useRecoilValue(webInfoState);
// //   const [loanType, setLoanType] = useState('Select')
// //   let [loanYear] = useInput({
// //     data: data.loanYear,
// //     status: props.action == UPDATE,
// //     initial: "",
// //   });

// //   let [loanAmountUpto] = useInput({
// //     data: data.loanAmountUpto,
// //     status: props.action == UPDATE,
// //     initial: "",
// //   });

// //   let [mortgageRate] = useInput({
// //     data: data.mortgageRate,
// //     status: props.action == UPDATE,
// //     initial: "",
// //   });

// //   let [APRrates] = useInput({
// //     data: data.APRrates,
// //     status: props.action == UPDATE,
// //     initial: "",
// //   });

// //   const handleSubmit = (event) => {
// //     const apiData = {
// //       websiteId: webInfo.id,
// //       id: data.id,
// //       mortgageRate: mortgageRate.value,
// //       loanAmountUpto: loanAmountUpto.value,
// //       loanYear: loanYear.value,
// //       APRrates: APRrates.value,
// //       loanType: loanType,
// //     };
// //     postApiCallDynamicWithOutReturn(
// //       {
// //         data: apiData,
// //         refresh: () => {
// //           setTableReload((prev) => !prev);
// //           handleClose();
// //         },
// //         path: API.rates,
// //         header: event.target.id
// //       }
// //     );
// //   };

// //   const handleValidation = (values) => {
// //     let errors = {};
// //     if (loanYear.value === "") {
// //       errors.loanYear = "Select a loan Year to proceed";
// //     }
// //     if (loanAmountUpto.value === "") {
// //       errors.loanAmountUpto = "Enter a loan Amount Upto proceed";
// //     }
// //     if (mortgageRate.value === "") {
// //       errors.mortgageRate = "Enter  mortgage Rate to proceed";
// //     }
// //     if (APRrates.value === "") {
// //       errors.APRrates = "Enter APR rates to proceed";
// //     }

// //     return errors;
// //   };

// //   const formik = useFormik({
// //     initialValues: {
// //       loanYear: loanYear.value,
// //       loanAmountUpto: loanAmountUpto.value,
// //       mortgageRate: mortgageRate.value,
// //       APRrates: APRrates.value,
// //     },
// //     validate: (values) => handleValidation(values),
// //     onSubmit: (values) => {
// //       handleSubmit({ target: { id: props.action } });
// //     },
// //   });
// //   return (
// //     <form onSubmit={formik.handleSubmit}>
// //       <Box>
// //         <CustomInput
// //           size="small"
// //           fullWidth
// //           boxProps={{ my: 2 }}
// //           label={"Loan Amount Up to"}
// //           required={true}
// //           placeholder={"600,000"}
// //           change={loanAmountUpto}
// //           type="number"
// //           error={formik.errors.loanAmountUpto}
// //         />
// //       </Box>
// //       <Box>
// //         <CustomInput
// //           size="small"
// //           fullWidth
// //           boxProps={{ my: 2 }}
// //           label={"Loan Term"}
// //           required={true}
// //           placeholder={"30 years"}
// //           change={loanYear}
// //           type="text"
// //           error={formik.errors.loanYear}
// //         />
// //       </Box>
// //       <Box>
// //         <CustomDropDown
// //           label="Loan Type"
// //           value={loanType}
// //           // placeholder={loanType}
// //           // defaultValue={loanType}
// //           rowData={loanType}
// //           option={TYPES_OF_LOAN.map((i) => {
// //             return {
// //               value: i.value,
// //               label: i.value,
// //             };
// //           })}
// //           changeCallBack={(e) => setLoanType(e.value)}
// //         />
// //       </Box>
// //       <Box>
// //         <CustomInput
// //           size="small"
// //           fullWidth
// //           boxProps={{ my: 2 }}
// //           label={"Mortgage Rate (%)"}
// //           change={mortgageRate}
// //           required={true}
// //           placeholder={"6.000"}
// //           type="number"
// //           error={formik.errors.mortgageRate}
// //         />
// //       </Box>
// //       <Box>
// //         <CustomInput
// //           size="small"
// //           fullWidth
// //           boxProps={{ my: 2 }}
// //           label={"APR Rate (%)"}
// //           required={true}
// //           placeholder={"6.500"}
// //           change={APRrates}
// //           type="number"
// //           error={formik.errors.APRrates}
// //         />
// //       </Box>
// //       <Box my={2} display="flex" flexDirection={"row"} justifyContent={"end"}>
// //         <Button
// //           variant="contained"
// //           id={props.action}
// //           type="submit"
// //         // onClick={handleSubmit}
// //         >
// //           {MODAL_ACTION[props.action]}
// //         </Button>
// //       </Box>
// //     </form>
// //   );
// // }


// import { Box, Button } from "@mui/material";
// import { AttachMoney } from "@mui/icons-material";
// import { useFormik } from "formik";
// import React, { useState } from "react";
// import { useRecoilValue } from "recoil";
// import { MODAL_ACTION, TYPES_OF_LOAN, UPDATE } from "../../../../constants/constatnt-variable";
// import CustomInput from "../../../../helpers/custom-input";
// import useInput from "../../../../helpers/use-input";
// import { webInfoState } from "../../../../recoil/profile-atom";
// import { API } from "../../../../services/api-config";
// import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
// import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";

// export default function RatesForm({
//   data,
//   handleClose,
//   setTableReload,
//   ...props
// }) {
//   let webInfo = useRecoilValue(webInfoState);
//   const [loanType, setLoanType] = useState('Select');


//   let [loanYear] = useInput({
//     data: data.loanYear,
//     status: props.action === UPDATE,
//     initial: "",
//   });

//   let [loanAmountUpto] = useInput({
//     data: data.loanAmountUpto,
//     status: props.action === UPDATE,
//     initial: "",
//   });

//   let [mortgageRate] = useInput({
//     data: data.mortgageRate,
//     status: props.action === UPDATE,
//     initial: "",
//   });

//   let [APRrates] = useInput({
//     data: data.APRrates,
//     status: props.action === UPDATE,
//     initial: "",
//   });

//   const formatNumberWithThousandSeparator = (value) => {
//     if (!value) return "";
//     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   };

//   const stripThousandSeparator = (value) => {
//     return value.replace(/,/g, "");
//   };

//   const handleLoanAmountChange = (event) => {
//     let value = event.target.value.replace(/,/g, ""); // Strip existing separators
//     if (isNaN(value)) return;
//     loanAmountUpto.onChange({ target: { value: value } });
//     setFormattedLoanAmount(formatNumberWithThousandSeparator(value));
//   };

//   const handleSubmit = (event) => {
//     const apiData = {
//       websiteId: webInfo.id,
//       id: data.id,
//       mortgageRate: mortgageRate.value,
//       loanAmountUpto: stripThousandSeparator(loanAmountUpto.value),
//       loanYear: loanYear.value,
//       APRrates: APRrates.value,
//       loanType: loanType,
//     };
//     postApiCallDynamicWithOutReturn(
//       {
//         data: apiData,
//         refresh: () => {
//           setTableReload((prev) => !prev);
//           handleClose();
//         },
//         path: API.rates,
//         header: event.target.id
//       }
//     );
//   };

//   const handleValidation = (values) => {
//     let errors = {};
//     if (loanYear.value === "") {
//       errors.loanYear = "Select a loan Year to proceed";
//     }
//     if (loanAmountUpto.value === "") {
//       errors.loanAmountUpto = "Enter a loan Amount Upto proceed";
//     }
//     if (mortgageRate.value === "") {
//       errors.mortgageRate = "Enter mortgage Rate to proceed";
//     }
//     if (APRrates.value === "") {
//       errors.APRrates = "Enter APR rates to proceed";
//     }

//     return errors;
//   };

//   const formik = useFormik({
//     initialValues: {
//       loanYear: loanYear.value,
//       loanAmountUpto: loanAmountUpto.value,
//       mortgageRate: mortgageRate.value,
//       APRrates: APRrates.value,
//     },
//     validate: (values) => handleValidation(values),
//     onSubmit: (values) => {
//       handleSubmit({ target: { id: props.action } });
//     },
//   });

//   const [formattedLoanAmount, setFormattedLoanAmount] = useState(formatNumberWithThousandSeparator(data.loanAmountUpto || ""));

//   return (
//     <form onSubmit={formik.handleSubmit}>
//       <Box>
//         <CustomInput
//           size="small"
//           fullWidth
//           boxProps={{ my: 2 }}
//           label={"Loan Amount Up to"}
//           required={true}
//           placeholder={"600,000"}
//           type="text"
//           error={formik.errors.loanAmountUpto}
//           value={formattedLoanAmount}
//           onChange={handleLoanAmountChange}
//           sicon={<AttachMoney />}
//         />
//       </Box>
//       <Box>
//         <CustomInput
//           size="small"
//           fullWidth
//           boxProps={{ my: 2 }}
//           label={"Loan Term"}
//           required={true}
//           placeholder={"30 years"}
//           change={loanYear}
//           type="text"
//           error={formik.errors.loanYear}
//         />
//       </Box>
//       <Box>
//         <CustomDropDown
//           label="Loan Type"
//           value={loanType}
//           rowData={loanType}
//           option={TYPES_OF_LOAN.map((i) => {
//             return {
//               value: i.value,
//               label: i.value,
//             };
//           })}
//           changeCallBack={(e) => setLoanType(e.value)}
//         />
//       </Box>
//       <Box>
//         <CustomInput
//           size="small"
//           fullWidth
//           boxProps={{ my: 2 }}
//           label={"Mortgage Rate (%)"}
//           change={mortgageRate}
//           required={true}
//           placeholder={"6.000"}
//           type="number"
//           error={formik.errors.mortgageRate}
//         />
//       </Box>
//       <Box>
//         <CustomInput
//           size="small"
//           fullWidth
//           boxProps={{ my: 2 }}
//           label={"APR Rate (%)"}
//           required={true}
//           placeholder={"6.500"}
//           change={APRrates}
//           type="number"
//           error={formik.errors.APRrates}
//         />
//       </Box>
//       <Box my={2} display="flex" flexDirection={"row"} justifyContent={"end"}>
//         <Button
//           variant="contained"
//           id={props.action}
//           type="submit"
//         >
//           {MODAL_ACTION[props.action]}
//         </Button>
//       </Box>
//     </form>
//   );
// }
import { Box, Button } from "@mui/material";
import { AttachMoney } from "@mui/icons-material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { MODAL_ACTION, TYPES_OF_LOAN, UPDATE } from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import useInput from "../../../../helpers/use-input";
import { webInfoState } from "../../../../recoil/profile-atom";
import { API } from "../../../../services/api-config";
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import CustomDropDownWebsite from "../../../../helpers/dropdown/custom-dropdown-website";

export default function RatesForm({
  data,
  handleClose,
  setTableReload,
  ...props
}) {
  let webInfo = useRecoilValue(webInfoState);
  const [loanCategory, setLoanCategory] = useState(data?.loanCategory || 'Select');

  useEffect(() => {
    if (data.loanCategory) {
      setLoanCategory(data.loanCategory); // Sync with backend data
    }
  }, [data.loanCategory]);
  console.log(" Fetched loan category " + data?.loanCategory)
  let [loanYear] = useInput({
    data: data.loanYear,
    status: props.action === UPDATE,
    initial: "",
  });

  let [loanAmountUpto] = useInput({
    data: data.loanAmountUpto,
    status: props.action === UPDATE,
    initial: "",
  });

  let [mortgageRate] = useInput({
    data: data.mortgageRate,
    status: props.action === UPDATE,
    initial: "",
  });

  let [APRrates] = useInput({
    data: data.APRrates,
    status: props.action === UPDATE,
    initial: "",
  });

  const formatNumberWithThousandSeparator = (value) => {
    if (!value) return "";
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const stripThousandSeparator = (value) => {
    return value.replace(/,/g, "");
  };

  const handleLoanAmountChange = (event) => {
    let value = event.target.value.replace(/,/g, ""); // Strip existing separators
    if (isNaN(value)) return;
    loanAmountUpto.onChange({ target: { value: value } });
    setFormattedLoanAmount(formatNumberWithThousandSeparator(value));
  };

  const handleSubmit = (event) => {
    const apiData = {
      websiteId: webInfo.id,
      id: data.id,
      mortgageRate: mortgageRate.value,
      loanAmountUpto: stripThousandSeparator(loanAmountUpto.value),
      loanYear: loanYear.value,
      APRrates: APRrates.value,
      loanCategory: loanCategory,
    };
    postApiCallDynamicWithOutReturn({
      data: apiData,
      refresh: () => {
        setTableReload((prev) => !prev);
        handleClose();
      },
      path: API.rates,
      header: event.target.id
    });
  };

  const handleValidation = (values) => {
    let errors = {};
    // if (loanYear.value === "") {
    //   errors.loanYear = "Select a loan Year to proceed";
    // }
    if (loanYear.value === "") {
      errors.loanYear = "Loan Term is required.";
    } else if (isNaN(loanYear.value) || loanYear.value <= 0 || !Number.isInteger(Number(loanYear.value))) {

      errors.loanYear = "Enter valid loan term";
    }
    if (loanAmountUpto.value === "") {
      errors.loanAmountUpto = "Enter a loan Amount Upto proceed";
    }
    if (mortgageRate.value === "") {
      errors.mortgageRate = "Enter mortgage Rate to proceed";
    }
    if (APRrates.value === "") {
      errors.APRrates = "Enter APR rates to proceed";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      loanYear: loanYear.value,
      loanAmountUpto: loanAmountUpto.value,
      mortgageRate: mortgageRate.value,
      APRrates: APRrates.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit({ target: { id: props.action } });
    },
  });

  const [formattedLoanAmount, setFormattedLoanAmount] = useState(formatNumberWithThousandSeparator(data.loanAmountUpto || ""));

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"Loan Amount Up to"}
          required={true}
          placeholder={"600,000"}
          type="text"
          error={formik.errors.loanAmountUpto}
          value={formattedLoanAmount}
          onChange={handleLoanAmountChange}
          sicon={<AttachMoney />}
        />
      </Box>
      <Box>
        {/* <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"Loan Term"}
          required={true}
          placeholder={"30 years"}
          change={loanYear}
          type="text"
          error={formik.errors.loanYear}
        /> */}
        <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"Loan Term"}
          required={true}
          placeholder={"30 years"}
          value={loanYear.value}
          change={loanYear}
          type="text"
          error={formik.errors.loanYear}
          onChange={(event) => {

            const value = event.target.value.replace(/[^0-9]/g, '');
            loanYear.onChange({ target: { value: value } });
          }}
        />


      </Box>
      <Box>
        <CustomDropDownWebsite
          label="Loan Type"
          value={loanCategory}

          rowData={loanCategory}
          option={TYPES_OF_LOAN.map((i) => {
            return {
              value: i.value,
              label: i.value,
            };
          })}
          changeCallBack={(e) => setLoanCategory(e.value)}
        />
      </Box>
      <Box>
        <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"Mortgage Rate (%)"}
          change={mortgageRate}
          required={true}
          placeholder={"6.000"}
          type="number"
          error={formik.errors.mortgageRate}
        />
      </Box>
      <Box>
        <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"APR Rate (%)"}
          required={true}
          placeholder={"6.500"}
          change={APRrates}
          type="number"
          error={formik.errors.APRrates}
        />
      </Box>
      <Box my={2} display="flex" flexDirection={"row"} justifyContent={"end"}>
        <Button
          variant="contained"
          id={props.action}
          type="submit"
        >
          {MODAL_ACTION[props.action]}
        </Button>
      </Box>
    </form>
  );
}
