import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CustomDropDownWebsite({
    option,
    value, // Controlled value
    defaultValue = null, // Default value if uncontrolled
    changeCallBack = () => { },
    disabled,
    id,
    justifyContent = "center",
    state,
    style,
    label,
    required = false,
    field,
    colorDisabled,
    limitWidth,
    labelComponent,
    backgroundColor,
    labelRightComponent,
    error = null,
}) {
    const handleChange = (event) => {
        const newValue = event.target.value;
        state?.saveValue(newValue);
        changeCallBack({ id: id, value: newValue });
    };

    return (
        <Box justifyContent={justifyContent} mt={0.5} mb={2}>
            {labelComponent ? (
                labelComponent
            ) : (
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <InputLabel>
                        {label}
                        {required ? (
                            <Typography component={"span"} color={"error.main"}>
                                *
                            </Typography>
                        ) : null}
                    </InputLabel>
                    <InputLabel>{labelRightComponent}</InputLabel>
                </Stack>
            )}
            <FormControl
                style={limitWidth ? { minWidth: "50%" } : {}}
                fullWidth={!limitWidth}
            >
                <Select
                    style={style}
                    disabled={disabled}
                    displayEmpty
                    value={value || ""} // Controlled by `value`
                    onChange={handleChange}
                    defaultValue={null}
                    {...field}
                >
                    <MenuItem
                        color="Primary"
                        style={{ display: "none" }}
                        name={null}
                        value={""}
                        disabled
                    >
                        <InputLabel htmlFor="Select">Select Option</InputLabel>
                    </MenuItem>
                    {option?.map((item) => (
                        <MenuItem
                            key={item.value}
                            name={item.label}
                            value={item.value}
                            style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                        >
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {error && (
                <FormHelperText sx={{ pl: 2, fontSize: 14 }} error>
                    {error}
                </FormHelperText>
            )}
        </Box>
    );
}
