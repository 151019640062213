import { Box, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { API } from '../../../../../services/api-config'
import { UPDATE } from '../../../../../constants/constatnt-variable'
import LoadingOverlay from '@ronchalant/react-loading-overlay'
import { CreditReportValidation } from '../../../../../constants/validation-rules'
import FilePreview from '../../../../broker/leads/file-preview'
import { PreviewPDFModal } from '../../../../../helpers/modal/modal'
import { KSCloudDownloadIcon, KSDocumentSearchIcon } from '../../../../../icons/custome-icon'
import { downloadReportFromServer, documentPreview, documentPreviewOld } from '../../../../../helpers/file-upload-download'
import { kstheme } from "../../../../../theme/theme"

export default function DownlaodCreditReport({ creditReportId }) {
    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

    const downloadDocument = () => {
        downloadReportFromServer(API.creditReportDownload, {
            id: creditReportId

        })
    }

    const previewDocument = () => {



        const fileUrl = documentPreviewOld(API.creditReportDownload, {
            id: creditReportId,

        }).then((response) => {



            < FilePreview
                fileUrl={response}
                downloadPdf={downloadDocument}
            />

        })

    }

    return (

        <>
            <Box alignSelf="left" mb={3}>
                <Box py={2} border={'1px solid rgba(17, 17, 17, 0.10)'} borderRadius={'4px'} width={isMobile ? '100%' : '40%'} display={'flex'} justifyContent={'space-evenly'}>
                    <Box >
                        <Typography variant='h4'>{`Credit Report`}</Typography>
                        <Typography variant='p' color={'#222222BF'}>valid till 90 days from pull</Typography>
                    </Box>
                    <Stack direction={'column'} gap={1} justifyContent="flex-start" alignItems="flex-start" >
                        <Stack direction={'row-reverse'} gap={1} alignSelf={{ xs: 'center', md: 'auto' }}>
                            <IconButton color='primary' component="label" onClick={downloadDocument}>

                                <KSCloudDownloadIcon />
                            </IconButton>
                            <IconButton color='primary' onClick={previewDocument}>
                                <KSDocumentSearchIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Box>

            </Box>
        </>
    );


}
