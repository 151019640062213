

import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { KSCloudUploadIcon, KSDocumentSearchIcon } from '../../icons/custome-icon'
import { uploadFileWithToken } from '../../services/api-service';
import { API } from '../../services/api-config';
import { toastNotify } from '../../helpers/alert-msg';
import { docPreviewResolve, downloadReportFromServer, downloadReportFromServerThirdParty } from '../../helpers/file-upload-download';
import { KSTooltip } from '../../helpers/ks-tooltip';


const ThirdPartyUploadCard = ({ item, formId, accessCode, taskCode, formData, setUploadFile, taskStatus }) => {

    const [localFileDetails, setLocalFileDetails] = useState(null);



    const fileUpload = async (event, item) => {

        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            try {
                const res = await uploadFileWithToken({

                    file: file,
                    serviceType: "task",
                    formId: formId,
                    thirdPartyTaskCode: taskCode,
                    path: API.proxyDocumentThirdPartyOthersUpload,
                    token: accessCode,
                });




                if (res.status === 201) {
                    toastNotify("success", res.data.message);


                    const updatedData = {
                        ...res?.data,
                        uploadId: item?.id
                    };



                    setUploadFile(updatedData)

                    setLocalFileDetails(res.data);
                } else {
                    toastNotify("error", res.data.message);
                }
            } catch (error) {
                console.error("Upload failed: ", error);
                toastNotify("error", "File upload failed.");
            }
        } else {
            console.log("No file selected");
        }
    };


    const downloadDocument = (item) => {

        const apiUrl = API.proxyDocumentDownloadThirdParty
        const token = accessCode
        let storageKey
        let fileName
        let docId

        if (localFileDetails === null) {
            storageKey = item?.resolvedDocStorageKey
            fileName = item?.resolvedDocFileName
            docId = item?.resolvedDocId

        } else {
            storageKey = localFileDetails?.storageKey || item?.storageKey
            fileName = localFileDetails?.fileName
            docId = localFileDetails?.docId

        }

        const param = {
            storageKey: storageKey,
            // fileName: fileName,
            id: docId,
            thirdPartyTaskCode: taskCode

        }

        if (storageKey && fileName) {

            docPreviewResolve(apiUrl, param, token)
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        } else {

        }



    };


    return (
        <div>
            <Box sx={{ border: '1px solid lightgray', pX: 1 }} mb={2} padding={1}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >

                    <Box mt={1} mb={1} >
                        <KSTooltip title={item?.documentNote || 'No description available'}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {item?.documentType}
                            </Typography>
                        </KSTooltip>

                        <Typography variant="body2" color="grey">
                            {localFileDetails?.fileName || item?.resolvedDocFileName}
                        </Typography>
                    </Box>

                    <Box>



                        <IconButton
                            color="primary"

                            //   disabled={!localFileDetails?.fileName}
                            onClick={() => downloadDocument(item)}
                        >
                            <KSDocumentSearchIcon />
                        </IconButton>
                        <Button
                            component="label"
                            size="small"
                            startIcon={<KSCloudUploadIcon color={"primary"} />}
                            //
                            disabled={taskStatus === 'RESOLVED'}

                        >
                            <input
                                onChange={(e) => fileUpload(e, item)}
                                hidden
                                // accept="image/*"
                                type="file"
                            />
                            Upload
                        </Button>
                    </Box>


                </Stack>
            </Box>


        </div>
    );
}

export default ThirdPartyUploadCard;
