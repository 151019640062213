import axios from "axios";
import { APIConfig } from "../auth/config";
import { AlertError } from "./alert-msg";
import { API } from "../services/api-config";
import FilePreview from "../pages/broker/leads/file-preview";


export const downloadReportFromServer = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }


    return axios
        .get(
            url,
            {
                params: params,
                responseType: "blob",
            }


        )
        .then((response) => {
            let filename = "";
            let blob = new Blob([response.data]),
                downloadUrl = window.URL.createObjectURL(blob),
                disposition = response.headers["content-disposition"];


            if (disposition && disposition.indexOf("attachment") !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    matches = filenameRegex.exec(disposition);

                if (matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            let a = document.createElement("a");
            if (typeof a.download === "undefined") {
                window.location.href = downloadUrl;
            } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            }
        })
        .catch((error) => AlertError(error));
};






export const downloadReportFromServerThirdParty = (url, token, data, params = {}) => {



    return axios
        .get(url, data, {
            params: params,
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            let filename = "";
            let blob = new Blob([response.data]),
                downloadUrl = window.URL.createObjectURL(blob),
                disposition = response.headers["content-disposition"];



            if (disposition && disposition.indexOf("attachment") !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    matches = filenameRegex.exec(disposition);

                if (matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            let a = document.createElement("a");
            if (typeof a.download === "undefined") {
                window.location.href = downloadUrl;
            } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        })
        .catch((error) => {
            console.error("Error downloading the file:", error);
            AlertError(error);
        });
};


export const getFileUrl = (id, type) => {
    return process.env.REACT_APP_BASE_URL + API.leadDownload + `?id=${id}&&serviceType=${type}&&shouldPreview=true`
}

export const getImageUrl = (id, fileName, type) => {
    if (id && fileName) {
        return process.env.REACT_APP_BASE_URL + API.proxyUserDownload + `?id=${id}&serviceType=${type}&fileName=${fileName}`
    }
}

export const documentPreviewOld = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }

    return axios
        .get(
            url,
            {
                params: params,
                responseType: "arraybuffer",
            }
        )
        .then((response) => {
            const headers = response.headers || {};
            const contentType = headers["content-type"];

            if (!contentType) {
                console.error("Content type not found in headers.");
                return;
            }

            if (contentType.includes("application/pdf")) {
                // Handle PDF preview
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                window.open(url, '_blank');
            } else if (contentType.includes("image")) {
                // Handle image preview
                const url = URL.createObjectURL(new Blob([response.data], { type: contentType }));
                window.open(url, '_blank');
            }
            else {
                // Handle PDF preview
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                window.open(url, 'PopupWindow', 'width=600,height=400,toolbar=no,location=no,menubar=no,status=no,scrollbars=no,resizable=yes');
            }
        });
};


export const documentPreview = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }



    return axios
        .get(url, {
            params: params,
            responseType: 'blob',
        })
        .then((response) => {
            const contentType = response.headers['content-type'];
            let blob;


            if (contentType.includes('pdf')) {
                blob = new Blob([response.data], { type: 'application/pdf' });
            } else if (contentType.includes('image/jpeg')) {
                blob = new Blob([response.data], { type: 'image/jpeg' });
            } else if (contentType.includes('image/png')) {
                blob = new Blob([response.data], { type: 'image/png' });
            } else {
                console.error('Unsupported file type: ' + contentType);
                return;
            }

            const urlObject = URL.createObjectURL(blob);


            const windowName = 'popupWindow';
            const windowFeatures = 'width=600,height=800';


            const popupWindow = window.open(urlObject, windowName, windowFeatures);


            if (popupWindow === null || typeof popupWindow === 'undefined') {
                alert('Popup window was blocked by the browser. Please allow popups and try again.');
            }

            return urlObject;

        })
        .catch((error) => AlertError(error));
};


export const docPreviewWithToken = (url, data, token) => {
    let params = {};
    if (data) {
        params = data;
    }



    return axios
        .get(url, {
            params: params,
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${token}`, // Add Bearer token
            }
        })
        .then((response) => {

            let contentType = response.headers['content-type'] || 'application/octet-stream';
            let blob;


            if (contentType === 'application/octet-stream' && data.fileName) {
                const extension = data.fileName.split('.').pop().toLowerCase();

                if (extension === 'pdf') {
                    contentType = 'application/pdf';
                } else if (extension === 'jpg' || extension === 'jpeg') {
                    contentType = 'image/jpeg';
                } else if (extension === 'png') {
                    contentType = 'image/png';
                } else {
                    console.error('Unsupported file extension:', extension);
                    throw new Error('Unsupported file type');
                }
            }


            blob = new Blob([response.data], { type: contentType });


            const fileUrl = URL.createObjectURL(blob);



            const iframe = document.getElementById('previewIframe');
            if (iframe) {

                iframe.src = fileUrl;
            } else {

                const windowName = 'popupWindow';
                const windowFeatures = 'width=600,height=800';
                const popupWindow = window.open(fileUrl, windowName, windowFeatures);


                if (popupWindow === null || typeof popupWindow === 'undefined') {
                    alert('Popup window was blocked by the browser. Please allow popups and try again.');
                }
            }

            return fileUrl;
        })
        .catch((error) => {
            console.error('Error loading document:', error);
            AlertError(error);
        });
};
export const docDownloadWithToken = (url, data, token) => {
    let params = {};
    if (data) {
        params = data;
    }



    return axios
        .get(url, {
            params: params,
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${token}`, // Add Bearer token
            },
        })
        .then((response) => {

            let contentType = response.headers['content-type'] || 'application/octet-stream';
            let blob;


            if (contentType === 'application/octet-stream' && data.fileName) {
                const extension = data.fileName.split('.').pop().toLowerCase();

                if (extension === 'pdf') {
                    contentType = 'application/pdf';
                } else if (extension === 'jpg' || extension === 'jpeg') {
                    contentType = 'image/jpeg';
                } else if (extension === 'png') {
                    contentType = 'image/png';
                } else {
                    console.error('Unsupported file extension:', extension);
                    throw new Error('Unsupported file type');
                }
            }


            blob = new Blob([response.data], { type: contentType });


            const fileUrl = URL.createObjectURL(blob);


            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = data.fileName || 'downloaded_file'; // Use the fileName from data or a default name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);


        })
        .catch((error) => {
            console.error('Error downloading document:', error);
            AlertError(error);
        });
};


export const docPreview = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }



    return axios
        .get(url, {
            params: params,
            responseType: "blob",
        })
        .then((response) => {

            let contentType = response.headers['content-type'] || 'application/octet-stream';
            let blob;


            if (contentType === 'application/octet-stream' && data.fileName) {
                const extension = data.fileName.split('.').pop().toLowerCase();

                if (extension === 'pdf') {
                    contentType = 'application/pdf';
                } else if (extension === 'jpg' || extension === 'jpeg') {
                    contentType = 'image/jpeg';
                } else if (extension === 'png') {
                    contentType = 'image/png';
                } else {
                    console.error('Unsupported file extension:', extension);
                    throw new Error('Unsupported file type');
                }
            }


            blob = new Blob([response.data], { type: contentType });


            const fileUrl = URL.createObjectURL(blob);



            const iframe = document.getElementById('previewIframe');
            if (iframe) {

                iframe.src = fileUrl;
            } else {

                const windowName = 'popupWindow';
                const windowFeatures = 'width=600,height=800';
                const popupWindow = window.open(fileUrl, windowName, windowFeatures);


                if (popupWindow === null || typeof popupWindow === 'undefined') {
                    alert('Popup window was blocked by the browser. Please allow popups and try again.');
                }
            }

            return fileUrl;
        })
        .catch((error) => {
            console.error('Error loading document:', error);
            AlertError(error);
        });
};

export const docDownload = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }



    return axios
        .get(url, {
            params: params,
            responseType: "blob",
        })
        .then((response) => {

            let contentType = response.headers['content-type'] || 'application/octet-stream';
            let blob;


            if (contentType === 'application/octet-stream' && data.fileName) {
                const extension = data.fileName.split('.').pop().toLowerCase();

                if (extension === 'pdf') {
                    contentType = 'application/pdf';
                } else if (extension === 'jpg' || extension === 'jpeg') {
                    contentType = 'image/jpeg';
                } else if (extension === 'png') {
                    contentType = 'image/png';
                } else {
                    console.error('Unsupported file extension:', extension);
                    throw new Error('Unsupported file type');
                }
            }


            blob = new Blob([response.data], { type: contentType });


            const fileUrl = URL.createObjectURL(blob);


            const a = document.createElement('a');
            a.href = fileUrl;


            a.download = data?.data?.fileName || 'RequiredDocument';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);


        })
        .catch((error) => {
            console.error('Error downloading document:', error);
            AlertError(error);
        });
};




export const docPreviewResolve = (url, param, token) => {
    let params = param;


    //

    return axios
        .get(url, {
            params: params,
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${token}`, // Adding Bearer token for authorization
            },
        })
        .then((response) => {

            let contentType = response.headers['content-type'] || 'application/octet-stream';
            let blob;


            if (contentType === 'application/octet-stream' && data.fileName) {
                const extension = data.fileName.split('.').pop().toLowerCase();

                if (extension === 'pdf') {
                    contentType = 'application/pdf';
                } else if (extension === 'jpg' || extension === 'jpeg') {
                    contentType = 'image/jpeg';
                } else if (extension === 'png') {
                    contentType = 'image/png';
                } else {
                    console.error('Unsupported file extension:', extension);
                    throw new Error('Unsupported file type');
                }
            }


            blob = new Blob([response.data], { type: contentType });


            const fileUrl = URL.createObjectURL(blob);



            const iframe = document.getElementById('previewIframe');
            if (iframe) {

                iframe.src = fileUrl;
            } else {

                const windowName = 'popupWindow';
                const windowFeatures = 'width=600,height=800';
                const popupWindow = window.open(fileUrl, windowName, windowFeatures);


                if (popupWindow === null || typeof popupWindow === 'undefined') {
                    alert('Popup window was blocked by the browser. Please allow popups and try again.');
                }
            }

            return fileUrl;
        })
        .catch((error) => {
            console.error('Error loading document:', error);
            AlertError(error);
        });
};

export const docDownloadResolve = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }



    return axios
        .get(url, {
            params: params,
            responseType: "blob",
        })
        .then((response) => {

            let contentType = response.headers['content-type'] || 'application/octet-stream';
            let blob;


            if (contentType === 'application/octet-stream' && data.fileName) {
                const extension = data.fileName.split('.').pop().toLowerCase();

                if (extension === 'pdf') {
                    contentType = 'application/pdf';
                } else if (extension === 'jpg' || extension === 'jpeg') {
                    contentType = 'image/jpeg';
                } else if (extension === 'png') {
                    contentType = 'image/png';
                } else {
                    console.error('Unsupported file extension:', extension);
                    throw new Error('Unsupported file type');
                }
            }


            blob = new Blob([response.data], { type: contentType });


            const fileUrl = URL.createObjectURL(blob);


            const a = document.createElement('a');
            a.href = fileUrl;

            a.download = data.fileName || 'downloaded_file';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);


        })
        .catch((error) => {
            console.error('Error downloading document:', error);
            AlertError(error);
        });
};